<template>
  <div class="home">
    <img alt="Vue logo" src="../assets/logo.png" />
    4345345345
  </div>
</template>

<script>
// @ is an alias to /src
export default {
  name: 'Home',
  components: {
  },
  data () {
    return {

    }
  },
  created () {

  }
}
</script>
